@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,400;0,600;1,200;1,400;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: 'Roboto', sans-serif;
}

.antd-global > .ant-transfer > .ant-transfer-list {
  width: 415px;
  height: auto;
}
.antd-global > .ant-transfer > .ant-transfer-operation > :first-child {
  font-family: 'Roboto', sans-serif;
  width: 101px;
  height: 43px;
  background: #006acb;
  border-radius: 4px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.antd-global > .ant-transfer > .ant-transfer-operation > :last-child {
  font-family: 'Roboto', sans-serif;
  width: 130px;
  height: 43px;
  border-color: #006acb;
  background: transparent;
  border-radius: 4px;
  color: #006acb;
  display: flex;
  align-items: center;
  justify-content: center;
}
